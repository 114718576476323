import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Dropdown, message } from "antd"
import { getImageUrl } from "../../../../shared/utils/functions/images"
import DeleteIcon from '../../../../shared/assets/images/redTrash.svg'
import blueMailIcon from '../../../../shared/assets/images/blueMail.svg'
import blueMoveIcon from '../../../../shared/assets/images/blueMove.svg'
import grayMailIcon from '../../../../shared/assets/images/grayMail.svg'
import grayMoveIcon from '../../../../shared/assets/images/grayMove.svg'
import { setCandidateProfile, setCandidatesModalOpen, setIsSendEmailOpen } from "../../slice/candidateSlice"
import DeleteModal from "../../../../shared/components/Modals/DeleteModal"
import { WithTooltip } from "../../../../shared/components/tooltip.tsx/withTootip"
import { errorMessage, roleError } from "../../../../shared/utils/constantData"
import { ICandidate, ISearchCandidate } from "../../../../shared/utils/interfaces/candidatesListFace"
import { useDeleteCandidateMutation } from "../../slice/candiddateApiSlice"
import { useNavigate } from "react-router-dom"
import { permissionsActions } from "../../../../shared/utils/interfaces/AuthInterface"
import { usePermissions } from "../../../../shared/utils/customHooks/usePermissions"
import { templatesTypes } from "../../../../shared/utils/interfaces/settingsInterface"
import { setActiveTemplateTypeId } from "../../../settings/slice/templatesSlice"

function CandidatesActions({ withDropDown, candidate, isDeletingNavigateBack = true }: { withDropDown: boolean, candidate: ICandidate | ISearchCandidate, isDeletingNavigateBack?: boolean }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { hasJobPermission } = usePermissions()
    const { communication } = templatesTypes
    const [openWarningModal, setOpenWarningModal] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const { sendEmail, deleteCandidates, changeApplicationJob } = permissionsActions
    const [deleteCandidate, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete, isError: isErrorDeleting }] = useDeleteCandidateMutation()

    useEffect(() => {
        if (isSuccessDelete) {
            handleToggleDeleteModal()
            if (isDeletingNavigateBack) navigate(-1)
            message.success('Candidate deleted successfully')
        } else if (isErrorDeleting) message.error(errorMessage)
        // eslint-disable-next-line
    }, [isSuccessDelete, isErrorDeleting, isDeletingNavigateBack])

    const openCandidateModal = () => {
        dispatch(setCandidateProfile(candidate))
        dispatch(setCandidatesModalOpen(true))
    }

    const handleEmail = () => {
        dispatch(setCandidateProfile(candidate))
        candidate?.email ? dispatch(setIsSendEmailOpen(true)) : setOpenWarningModal(true)
    }

    const handleSendEmail=()=>{
        handleEmail()
        dispatch(setActiveTemplateTypeId(communication))
    }

    const handleToggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen)

    const handleDeleteCandidate = async () => await deleteCandidate({ id: candidate?.id })

    const moreCardActions = {
        items: [
            {
                key: 1,
                label: '',
                icon: <WithTooltip
                    visible={!hasJobPermission(candidate?.userRole?.roleName, changeApplicationJob)}
                    placement='left'
                    title={roleError}
                >
                    <img src={blueMoveIcon} alt="Move" className="inline w-5 btn-icon !me-3" />
                    Move to Job/Stage
                </WithTooltip>,
                onClick: openCandidateModal,
                className: `${!hasJobPermission(candidate?.userRole?.roleName, changeApplicationJob) ? 'grayscale opacity-30' : ''} !px-4 !text-indigo-700 hover:!bg-indigo-50`,
                disabled: !hasJobPermission(candidate?.userRole?.roleName, changeApplicationJob)
            },
            {
                key: 2,
                label: '',
                icon: <WithTooltip
                    visible={!hasJobPermission(candidate?.userRole?.roleName, sendEmail)}
                    placement='left'
                    title={roleError}
                >
                    <img src={blueMailIcon} alt="Send Email" className="inline w-5 btn-icon !me-3" />
                    Send Email
                </WithTooltip>,
                onClick: handleSendEmail,
                className: `${!hasJobPermission(candidate?.userRole?.roleName, sendEmail) ? 'grayscale opacity-30' : ''} !px-4 !text-indigo-700 hover:!bg-indigo-50`,
                disabled: !hasJobPermission(candidate?.userRole?.roleName, sendEmail)
            },
            {
                key: 3,
                label: '',
                icon: <WithTooltip
                    visible={!hasJobPermission(candidate?.userRole?.roleName, deleteCandidates)}
                    placement='left'
                    title={roleError}
                >
                    <img src={getImageUrl('Pencil_alt')} alt="Delete" className="inline w-5 btn-icon !me-3" />
                    Delete
                </WithTooltip>,
                onClick: handleToggleDeleteModal,
                className: `${!hasJobPermission(candidate?.userRole?.roleName, deleteCandidates) ? 'grayscale opacity-30' : ''} !px-4 !text-red-700 hover:!bg-indigo-50`,
                disabled: !hasJobPermission(candidate?.userRole?.roleName, deleteCandidates)
            }
        ],
        className: '!p-2',
    }

    return (
        <>
            {
                withDropDown ?
                    <Dropdown className='inline my-auto cursor-pointer h-fit' trigger={['click']} menu={moreCardActions}>
                        <img src={getImageUrl('DotsHorizontalBlue')} alt='...' className="ms-2" />
                    </Dropdown>
                    : <div className="flex">
                        <WithTooltip
                            visible={!hasJobPermission(candidate?.userRole?.roleName, sendEmail)}
                            placement='bottom'
                            title={roleError}
                        >
                            <div className='h-fit'>
                                <Button disabled={!hasJobPermission(candidate?.userRole?.roleName, sendEmail)} className="flex items-center px-3 ml-2 btn-secondary" onClick={handleSendEmail}><img src={grayMailIcon} alt='Send Email' /></Button>
                            </div>
                        </WithTooltip>
                        <WithTooltip
                            visible={!hasJobPermission(candidate?.userRole?.roleName, changeApplicationJob)}
                            placement="bottom"
                            title={roleError}
                        >
                            <div className="h-fit">
                                <Button disabled={!hasJobPermission(candidate?.userRole?.roleName, changeApplicationJob)} className="flex items-center px-3 ml-2 btn-secondary" onClick={openCandidateModal}><img src={grayMoveIcon} alt='Move' /></Button>
                            </div>
                        </WithTooltip>
                        <WithTooltip
                            visible={!hasJobPermission(candidate?.userRole?.roleName, deleteCandidates)}
                            placement="bottom"
                            title={roleError}
                        >
                            <div className="h-fit">
                                <Button disabled={!hasJobPermission(candidate?.userRole?.roleName, deleteCandidates)} className="flex items-center px-3 ml-2 btn-secondary" onClick={handleToggleDeleteModal}><img src={DeleteIcon} alt='Delete' /></Button>
                            </div>
                        </WithTooltip>
                    </div>

            }
            <DeleteModal
                isVisibleModal={openWarningModal}
                handleCloseModal={() => setOpenWarningModal(false)}
                title="Invalid Email Address"
                content={<p>You can’t email this candidate,<br />no invalid email address available or candidate does't has email</p>}
                footer={
                    <Button
                        className="w-full py-1 btn-default"
                        onClick={() => setOpenWarningModal(false)}
                    >
                        Ok
                    </Button>} />
            <DeleteModal
                isVisibleModal={isDeleteModalOpen}
                handleCloseModal={!isLoadingDelete ? handleToggleDeleteModal : () => { }}
                title="Delete Candidate"
                content={<p>Are you sure you want to delete this candidate?<br />This action is permanent and cannot be undone.</p>}
                footer={
                    <div className='grid gap-3 md:grid-cols-2'>
                        <Button
                            loading={isLoadingDelete}
                            className=' btn-md btn-danger'
                            onClick={handleDeleteCandidate}>
                            Delete
                        </Button>
                        <Button
                            disabled={isLoadingDelete}
                            className='btn-md'
                            htmlType='submit'
                            onClick={handleToggleDeleteModal}>
                            Cancel
                        </Button>
                    </div>}
            />

        </>
    )
}

export default CandidatesActions