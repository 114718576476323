import { useNavigate, useParams } from 'react-router-dom';
import { useGetCandidateByIdQuery } from '../../../slice/candiddateApiSlice';
import Comments from './Comments';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectcurrType, selectHasRunningAutomation, selectIsSendEmailOpen, setCandidateCv, setCandidateProfile, setComparisonCandidates, setCurrentHiringStages, setCurrentType, setHasRunningAutomation, setIsSendEmailOpen } from '../../../slice/candidateSlice';
import { Button } from 'antd';
import { errorMessage } from '../../../../../shared/utils/constantData';
import LazyLoader from '../../../../../shared/components/LazyLoader/load';
import { selectAllRolesPermissions, setRolePermissionsPerJob } from '../../../../authentication/slice/authSlice';
import Chart from './Chart';
import { currentTypes } from '../../../../../shared/utils/interfaces/candidatesListFace';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../../shared/utils/analytics';
import ProfileToggle from './ProfileToggle';
import { Warning } from '../../../../../shared/components/Warning/Warning';
import { DocViewerRef } from '@cyntler/react-doc-viewer';
import { getImageUrl } from '../../../../../shared/utils/functions/images';
import { CVViewer } from '../../../../../shared/components/pdf/CVViewer';
import ComposeEmail from '../SendEmail/ComposeEmail';
import AutomatedActionsNotifications from '../AutomatedActionsNotifications';
import { MoveCandidatesModal } from '../../../../../shared/components/Modals/MoveCandidatesModal';

export const Profile = () => {
    const { applicationId } = useParams();
    const dispatch = useDispatch();
    const allRolesPermissions = useSelector(selectAllRolesPermissions)
    const { data, isSuccess, isLoading, isError, isFetching } = useGetCandidateByIdQuery(applicationId, { refetchOnMountOrArgChange: true });
    const currentType = useSelector(selectcurrType);
    const navigate = useNavigate();
    const docViewerRef = useRef<DocViewerRef>(null);
    const isSendEmailOpen = useSelector(selectIsSendEmailOpen);
    const hasRunningAutomation = useSelector(selectHasRunningAutomation);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setCandidateProfile(data?.application));
            dispatch(setComparisonCandidates([data?.application]))
            dispatch(setCurrentHiringStages(data?.application?.pipelineStages.map((stage: any) => ({ id: stage?.id, name: stage?.name, count: '0' }))));
            dispatch(setCandidateCv(data?.application?.cv))
            dispatch(setRolePermissionsPerJob({ role: data?.application?.userRole?.roleName, permissions: allRolesPermissions ? allRolesPermissions[data?.application?.userRole?.roleName].permissions : [] }))
            dispatch(setCurrentType(currentTypes.CV))
            dispatch(setHasRunningAutomation(data?.application?.hasRunningAutomation))
            logEvent(analytics, 'View candidate Profile')
        }
    }, [isSuccess, data, dispatch, allRolesPermissions]);

    useEffect(() => {
        return () => {
            dispatch(setIsSendEmailOpen(false))
        }
    }, [dispatch])
    return (
        <>
            {isLoading || isFetching ?
                <div className="flex items-center justify-center w-screen h-screen">
                    <div className='w-full max-w-2xl mb-4'>
                        <LazyLoader />
                        <p className='mt-4 text-base font-normal text-indigo-600'>Retrieving candidate data..</p>
                    </div>
                </div>
                : isSuccess ?
                    <>
                        <div className='flex flex-wrap pt-2 lg:flex-nowrap'>
                            <div className="flex-shrink w-full lg:w-auto px-7">
                                <div className='sticky top-0 left-0 bg-light-100'>
                                    <Button className="p-0 my-3 text-indigo-700 border-0 shadow-none btn-md min-w-max" onClick={() => navigate(-1)}>
                                        <img src={getImageUrl('blueLeftArrow')} className="inline w-4 mr-2 btn-icon" alt="back" />
                                        <span className="inline">Back</span>
                                    </Button>
                                    <ProfileToggle docViewerRef={docViewerRef} />
                                </div>
                            </div>
                            <div className='basis-full lg:basis-[50%] shrink lg:w-auto'>
                                {currentType === currentTypes.Graph ? <div className='pt-16'><Chart /></div> : <CVViewer docViewerRef={docViewerRef} />}
                            </div>
                            <div className='basis-full lg:basis-[50%] grow'>
                                <Comments />
                            </div>
                        </div>
                        <div className={`${!isSendEmailOpen ? 'opacity-0 pointer-events-none' : ''} w-full pb-14`}>
                            <ComposeEmail />
                        </div>
                        {hasRunningAutomation && <AutomatedActionsNotifications />}
                        
                    </>
                    : isError ?
                        <Warning className='pt-24' title={errorMessage} message='Please refresh to try again' btnText='Refresh' />
                        : null
            }
            <MoveCandidatesModal />

        </>
    )
}
