import React from 'react';
import { ISearchCandidate } from '../../../../../shared/utils/interfaces/candidatesListFace';
import { highlightText } from '../../../../../shared/utils/functions/highlightText';
import CandidatesActions from '../CandidatesActions';
import { WithTooltip } from '../../../../../shared/components/tooltip.tsx/withTootip';
import uploadDisabled from '../../../../../shared/assets/images/uploadDisabled.svg';
import ExclamationYellow from '../../../../../shared/assets/images/ExclamationYellow.svg';
import { useNavigate } from 'react-router-dom';



export default function SearchCndidateCard({ candidate, query, onClick }: { candidate: ISearchCandidate, query: string, onClick: React.MouseEventHandler<HTMLElement> }) {
    const navigate = useNavigate();
    const handleTooltipClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(`/similarApplication/${candidate?.id}`);
    };
    return (
        <div className="relative flex-wrap items-center overflow-hidden transition-shadow duration-300 bg-white p-7 hover:shadow-md md:flex-nowrap justify-stretch rounded-2xl">
            <div className="flex w-full rounded-md cursor-pointer">
                <div className='w-3/4' onClick={onClick} >
                    <div className="flex text-base font-bold text-gray-600">
                        {candidate?.firstName && candidate?.firstName ?
                            <>
                                {highlightText(`${candidate?.firstName} ${candidate?.lastName}`, query)}
                                {candidate?.similarityTypes?.includes("name") && (
                                    <>
                                        <img src={ExclamationYellow} alt='Similarity detection' className='inline ms-3' />
                                        <div className='self-center text-xs font-normal text-yellow-600'>Similar name</div>
                                    </>
                                )}
                            </>
                            : candidate?.cvFileName}
                        <span>{candidate?.batchId &&
                            <WithTooltip
                                className='w-36'
                                placement='right'
                                title='This candidate was uploaded by admin'
                            >
                                <img src={uploadDisabled} alt='This candidate was uploaded by admin' className='inline ms-3' />
                            </WithTooltip>
                        }</span>
                        {candidate?.isSimilar &&
                            <WithTooltip
                                className='w-36'
                                placement='right'
                                colorText='#B45309'
                                backgroundColor='#FEF3C7'
                                width='w-48'

                                title={<>Similarity detection: It appears that the same candidate has applied for multiple positions.
                                    <h4 className='mt-3 cursor-pointer' onClick={handleTooltipClick}>Click to compare.</h4></>}
                            >
                                <img onClick={handleTooltipClick} src={ExclamationYellow} alt='Similarity detection' className='inline ms-3' />
                            </WithTooltip>}
                    </div>
                    {candidate?.department && (
                        <p className="text-sm font-normal text-gray-600">
                            {candidate?.department?.parentDepartment ? `${candidate?.department?.parentDepartment?.name} / ` : ''}{candidate?.department?.name}
                        </p>
                    )}
                    <p className="text-sm font-normal text-gray-600">{candidate && highlightText(candidate?.currentPosition, query)}</p>
                    <p className="text-sm font-normal text-gray-600">in {candidate && candidate?.title} / {candidate && highlightText(candidate?.stageName, query)}</p>
                    {(candidate?.email?.toLowerCase()?.includes(query?.toLowerCase() ?? '') || candidate?.similarityTypes?.includes("email")) && (
                        <p className="flex text-sm font-normal text-gray-600">
                            {candidate?.email && (
                                <>
                                    {highlightText(candidate?.email, query)}
                                    {candidate?.similarityTypes?.includes("email") && (
                                        <>
                                            <img src={ExclamationYellow} alt="Similarity detection" className="inline ms-3" />
                                            <div className="self-center text-xs font-normal text-yellow-600">Similar email address</div>
                                        </>
                                    )}
                                </>
                            )}
                        </p>
                    )}

                    {candidate?.similarityTypes?.includes("phone") && candidate?.phone && (
                        <p className="flex text-sm font-normal text-gray-600">
                            {highlightText(candidate?.phone, query)}
                            <>
                                <img src={ExclamationYellow} alt='Similarity detection' className='inline ms-3' />
                                <div className='self-center text-xs font-normal text-yellow-600'>Similar phone number</div>
                            </>
                        </p>
                    )}
                    <p className="text-sm font-normal text-gray-600">{candidate?.snippet ? <>..{highlightText(candidate?.snippet, query)}..</> : ''}</p>
                </div>
                <div className='content-center w-1/4 text-right' >
                    <CandidatesActions withDropDown={true} candidate={candidate} isDeletingNavigateBack={false} />
                </div>
            </div>
        </div >
    );
}
