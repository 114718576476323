import { ICriteria } from "./interfaces/candidatesListFace";

// export const baseUrl = 'https://api.peoplecq.com/'; // ENABLE ON PROD
export const baseUrl = 'https://dev.api.workimize.com/';

export const filesBaseUrlProd = 'https://peoplecq-metadata-prod.s3.us-west-1.amazonaws.com';
export const filesBaseUrlDev = 'https://peoplecq-metadata.s3.eu-south-1.amazonaws.com';

export const assetsBaseUrl = 'https://peoplecq-frontend-assets.s3.us-west-1.amazonaws.com/assets/';
export const landingPageDir = 'landingPage/';

export const serverTypes = {
    dev: 'dev/'
}

export const validationMsgs = {
    invalidEmail: 'Not a valid email address',
    invalidUrl: 'Not a valid URL',
    invalidPhone: 'Not a valid phone number'
}

export const employmentTypes = [
    {
        value: 'fullTime',
        name: 'Full-time'
    },
    {
        value: 'partTime',
        name: 'Part-time'
    },
    {
        value: 'temporary',
        name: 'Temporary'
    }
];
export const jobTypes = [
    {
        value: 'onsite',
        name: 'On-Site'
    },
    {
        value: 'hybrid',
        name: 'Hybrid'
    },
    {
        value: 'remote',
        name: 'Remote'
    },
];

export enum apiStatuses {
    success = 200,
    success201 = 201,
    signupSuccess = 201,
    processingRequest = 202,
    notFound = 404,
    badRequest = 400,
    serverError = 500,
    serverTimeOut = 504,
    connectionTimeOut = 408,
    TooManyRequest = 425,
    unAuthorized = 401,
    requestsLimit = 429,
    requestForbidden = 403,
    serverFailedProcessing = 422
}
export const rephraseList = [
    {
        label: 'Make Shorter', key: 5,
    },
    {
        label: 'Make Longer', key: 4,
    },
    {
        label: 'Correct Sentence', key: 3,
    },
]
export const hiringStages = [
    {
        label: 'Send to ShortList', key: 1,
    },
    {
        label: 'Send to Assessment', key: 2,
    },
    {
        label: 'Send to Interview', key: 3,
    },
    {
        label: 'Send an Offer', key: 4,
    },
    {
        label: 'Hire Candidate', key: 5,
    }
]
export enum actions {

    regenerate = 1,

    changeTone = 2,

    correctSentence = 3,
    makeLonger = 4,
    makeShorter = 5,

    convertToBullets = 6,

    convertToParagraph = 7
}
export enum actionsName {
    regenerate = 'regenerate',
    changeTone = 'changeTone',
    useContent = 'useContent',
    rephrase = 'rephrase',
    toggle = 'toggle'
}
export const errorMessage = 'Something went wrong';
export const errorMessageAI = "Something went wrong generating content!";
export const removePipelineErrorMessage = 'Can’t remove! This hiring stage contains candidates'
export enum userNavigationActions {
    setting = 'Settings',
    logOut = 'Logout',
    myPage = 'Visit Career Portal'
}
export const userNavigation = [
    { name: userNavigationActions.setting },
    { name: userNavigationActions.logOut },
]


export enum tagTypes {
    job = 'Job',
    jobs = 'Jobs',
    user = 'User',
    candidates = "Candidates",
    candidate = 'Candidate',
    members = 'Members',
    comments = 'Comments',
    domainConfig = 'Domain Config',
    aiSearch = "aiSearch",
    templates = 'templates',
    automatedActions = 'automatedActions',
    search = 'search',
    departments = 'departments'
}

export const enum sampleDataStatusId {
    created = 3,
    failed = 4
}

export const supportedMail = 'support@peoplecq.com';
export const refundMail = 'refunds@peoplecq.com';
export const roleError = 'You don’t have access, please contact the admin'

export const breakpoints = {
    // Min width
    'min-screen-sm': 576,
    'min-screen-md': 768,
    'min-screen-lg': 992,
    'min-screen-xl': 1200,
    'min-screen-2x': 1366,
    'min-screen-3x': 1601,
    'min-screen-4x': 1921,

    // Max width
    'max-screen-sm': 767,
    'max-screen-md': 991,
    'max-screen-lg': 1199,
    'max-screen-xl': 1365,
    'max-screen-2x': 1600,
    'max-screen-3x': 1920,
    'max-screen-4x': 2800,

    // Mobile breakpoints
    'max-mobile-xs': 479,
    'max-mobile-mini': 575,
    'max-mobile-sm': 580,
}
export const mockCriteriaSummary = "Justification for this score is being generated.."
export const criteriaMockData: ICriteria[] = [
    {
        applicationId: 1,
        id: 1,
        jobCriteria: {
            criteriaName: "Certifications",
            id: 1,
            isMain: true,
            jobId: 1,
        },
        jobCriteriaId: 1,
        score: "NA",
        summary: mockCriteriaSummary,
        longSummary: mockCriteriaSummary
    },
    {
        applicationId: 1,
        id: 1,
        jobCriteria: {
            criteriaName: "Leadership",
            id: 1,
            isMain: true,
            jobId: 1,
        },
        jobCriteriaId: 1,
        score: "NA",
        summary: mockCriteriaSummary,
        longSummary: mockCriteriaSummary
    },
    {
        applicationId: 1,
        id: 1,
        jobCriteria: {
            criteriaName: "Adaptability",
            id: 1,
            isMain: true,
            jobId: 1,
        },
        jobCriteriaId: 1,
        score: "NA",
        summary: mockCriteriaSummary,
        longSummary: mockCriteriaSummary
    },
    {
        applicationId: 1,
        id: 1,
        jobCriteria: {
            criteriaName: "Teamwork",
            id: 1,
            isMain: true,
            jobId: 1,
        },
        jobCriteriaId: 1,
        score: "NA",
        summary: mockCriteriaSummary,
        longSummary: mockCriteriaSummary
    },
    {
        applicationId: 1,
        id: 1,
        jobCriteria: {
            criteriaName: "Time Management",
            id: 1,
            isMain: true,
            jobId: 1,
        },
        jobCriteriaId: 1,
        score: "NA",
        summary: mockCriteriaSummary,
        longSummary: mockCriteriaSummary
    }
]

export const jobsPerPage = 10;
export const AISearchPerPage = 10;
export const candidatesPerPage = 10;
export const SearchcandidatesPerPage = 10;
export const currentSizeOfUploadingFile = 3; // The current size of uploading one file
export const oneHour = 3600;
export const oneMinute = 60;
export const noDeptId = '-1'